<template>
  <div class="container-fluid page-title">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1>Unsubscribe</h1>
      </div>
    </div>
    <div class="container-fluid justify-content-between">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div>
                <div class="row">
                  <div class="col-md-6">
                    <b-form-input type="email" v-model="form.email" name="Email" placeholder="Email" />
                  </div>
                  <div class="col-md-6">
                    <div class="text-center">
                      <button
                          type="submit"
                          :disabled="loadingPage"
                          class="btn btn-info btn-fill float-left"
                          @click.prevent="triggerUnsubscribe">
                        Unsubscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <div class="row pt-2">
          <div class="col-md-6">
          <input class="form-control" v-model="searchByEmail" type="text"
                 placeholder="Search by email"
                 aria-label="Search">
          </div>
          <div class="col-md-6">
            <div class="text-center">
              <button
                type="submit"
                :disabled="loadingPage || !canSearch()"
                class="btn btn-info btn-fill float-left"
                @click.prevent="search">
                Search
              </button>
            </div>
          </div>
      </div>
      <div class="row pt-2">
        <div class="col-12">
          <table class="table row mx-0">
            <thead class="col-12 px-0 thead-light">
            <tr class="d-flex">
              <th class="col-4 collapse-text" scope="col">Email</th>
              <th class="col-4 collapse-text" scope="col">Created at</th>
              <th class="col-4 collapse-text" scope="col">Action</th>
            </tr>
            </thead>
            <tbody class="col-12 px-0">
            <tr class="d-flex"
                v-for="(email, index) in emails"
                :key="index">
              <td class="col-4 collapse-text">{{email.email}}</td>
              <td class="col-4 collapse-text" v-b-tooltip.hover :title="email.created_at">{{ email.created_at }}</td>
              <td class="col-4">
                <b-button v-b-tooltip.hover
                          size="sm"
                          title="Delete Unsubscribe"
                          variant="light"
                          class="user-entry-action col-6 ml-2"
                          @click="deleteUnsubscribe(email)">
                  <feather type="trash"></feather>
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 d-flex justify-content-center">
          <b-pagination :disabled="loadingPage"
                        @change="changePage"
                        v-model="currentPage"
                        :total-rows="total"
                        :per-page="itemsPerPage"
                        size="md">
          </b-pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { compose as Rcompose, length as Rlength, lt as Rlt } from 'ramda';

export default {
  name: 'unsubscribe',
  data() {
    return {
      emails: [],
      searchByEmail: '',
      loadingPage: false,
      itemsPerPage: 10,
      currentPage: 1,
      role: '',
      total: 0,
      loading: false,
      patient: null,
      form: {
        email: null,
      },
    };
  },
  mounted() {
    this.requestEmails();
  },
  methods: {
    validEmail(email) {
      const regex = [
        '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|',
        '(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|',
        '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      ].join('');

      return new RegExp(regex).test(email);
    },
    canSearch() {
      if (!this.searchByEmail) {
        return true;
      }

      return Rcompose(Rlt(4), Rlength)(this.searchByEmail);
    },
    search() {
      if (this.canSearch()) {
        this.requestEmails();
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.requestEmails();
    },
    triggerUnsubscribe() {
      if (!this.validEmail(this.form.email)) {
        this.$noty.error('Email is not valid');
        return;
      }

      const email = encodeURIComponent(this.form.email);

      this.loadingPage = true;

      axios.put(`/v2/communication/unsubscribe/${email}`).then(() => {
        this.$noty.success(`${email} unsubscribe.`);
        this.currentPage = 1;
        this.requestEmails();
      }).catch(err => {
        this.$noty.error('Error: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    deleteUnsubscribe(unsub) {
      this.loadingPage = true;
      const email = encodeURIComponent(unsub.email);
      axios.delete(`/v2/communication/unsubscribe/${email}`).then(() => {
        this.$noty.success(`${unsub.email} unsubscribe removed.`);
        this.currentPage = 1;
        this.requestEmails();
      }).catch(err => {
        this.$noty.error('Error: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    requestEmails() {
      this.loadingPage = true;
      const params = {
        page: this.currentPage,
        per_page: this.itemsPerPage,
        client: 'admin-ui',
      };

      if (this.searchByEmail) {
        params.search = this.searchByEmail;
      }

      axios.get('/v1/communication/unsubscribe', { params }).then(response => {
        this.emails = response.data.data.filter(item => !item.deleted_at);
        this.total = response.data.pagination.total;
      }).catch(err => {
        this.$noty.error('Failed to fetch users: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
  },
};
</script>

<style scoped>
.page-title {
  margin-top: 1%;
}
</style>
